import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carosel.css";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const BPData = {
  BP: [
    {
      tittle: "GYM Facility",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/GYM.JPG",
      description:
        "Institution provides gym facility for all students to keep up their health",
    },
    {
      tittle: "College Entrance",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Block%20C%20Entrance%20.JPG",
      description:
        "College welcomes all types of people across various segments. Easy access is provided for differently abled people.",
    },
    {
      tittle: "Computer Lab",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/COmputer Lab.JPG",
      description:
        "College provides good number of computer systems to the students to learn and practice programming.",
    },
    {
      tittle: "Cricket Academy",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Criket Academy.JPG",
      description:
        "College provides good sports facilities. Has good cricket ground for the students to play along with studies.",
    },
    {
      tittle: "Parking Area",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Parking area 2.JPG",
      description:
        "Good amount parking space is provided to the students and the visitors for easy access.",
    },
    {
      tittle: "NCC Room",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/NCC Room.JPG",
      description:
        "College has facility of NCC for the students to nurture their interest in NCC",
    },
    {
      tittle: "Library",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/library 2.JPG",
      description:
        "College provides good amount collection of books in the library for the students. They can enhance their knowledge with the available books.",
    },
    {
      tittle: "Play Ground",
      designation: "President",
      image:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/Infrastructure/Play ground.JPG",
      description:
        "College has good play ground facility to explore their interest of games.",
    },
  ],
};

const CustomArrow = ({ className, style, onClick, direction }) => (
  <button
    className={`custom-arrow ${direction}`}
    style={{ ...style }}
    onClick={onClick}
  >
   {direction === "next" ? (
        <ArrowRightIcon fontSize="large"/>
      ) : (
        <ArrowLeftIcon fontSize="large"/>
      )}
  </button>
);

const CampusCarousel = () => {


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section className="carousels-container mt-5">
      <h2 className="carousel-heading">Campus Life</h2>
      <div className="slider-container">
        <Slider {...settings}>
          {BPData.BP.map((slide, index) => {
            return (
              <div key={index} className="carousel-slide">
                <img
                  src={
                    slide.image
                      ? slide.image
                      : "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                  }
                  alt={slide.tittle}
                  className="carousel-image"
                />
                <p className="carousel-title">{slide.tittle}</p>
                <p className="carousel-description">{slide.description}</p>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default CampusCarousel;
