import React from "react";
// import MV from "../../Assets/JSON/MV.json"
import MVComponent from "../../Component/Missionvission/Mission";
import Grid from '@mui/material/Grid';
// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
// import Comapny_Carosel from "../../Component/Carosel/Company_Carosel";
// import image from "../../Assets/Images/Rectangle 27.png";


const MissionVission = (Props) => {
        const MVData = Props.MV_DATA;
    return (
        <section className="container mt-5">
            <div>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {/* <Item> */}
                    {MVData?.Vission && 
                        <MVComponent getdata={MVData?.Vission}></MVComponent>
                    }
                    {/* </Item> */}
                </Grid>
                <Grid item xs={12} md={6}>
                    {/* <Item> */}
                    {MVData?.Mission &&
                        <MVComponent getdata={MVData?.Mission}></MVComponent>
                    }
                    {/* </Item> */}
                </Grid>
            </Grid>
            </div>
            <div>
            {/* <Comapny_Carosel/> */}
            </div>
        </section>
    )
}
export default MissionVission