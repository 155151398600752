import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../Assets/Logo/logo.png";
import logo_name1 from "../Assets/Logo/law_college_logo.png";
import phone_black_icon from "../Assets/Icons/phone_black.svg";
import FounderImage from "../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import LawCollegeCertifications from "../Assets/Logo/LawCollege_certified.png";
import Law_cat_code from "../Assets/Logo/Law_cet_code.png";
import "../Styles/Style.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { relativeTimeRounding } from "moment";
import { Close } from "@mui/icons-material";

const navItems = [
  {
    id: 1,
    title: "About",
    pathname: "/about",
    subnav_flag: true,
    disabled: false,
    subnav: [
      {
        title: "AboutUs",
        pathname: "/aboutus",
        disabled: false,
      },
      {
        title: "InfraStructure",
        pathname: "/infrastructure",
        disabled: false,
      },
    ],
  },
  {
    id: 2,
    title: "Home",
    pathname: "/home",
    disabled: false,
  },
  {
    id: 3,
    title: "Academic",
    pathname: "/academics",
    subnav_flag: true,
    subnav: [
      {
        title: "Courses",
        subnav_flag: true,
        disabled: false,
        subnav: {
          LLB: [
            {
              title: "LLB-3YDC",
              pathname: "/courseoffering/llb/llb-3ydc",
              disabled: false,
            },
            {
              title: "LLB-5YDC",
              pathname: "/courseoffering/llb/llb-5ydc",
              disabled: false,
            },
          ],
          LLM: [
            {
              title: "Labour Law",
              pathname: "/courseoffering/llm/labour-law",
              disabled: false,
            },
            {
              title: "Constitutional Law",
              pathname: "/courseoffering/llm/constitutional-law",
              disabled: false,
            },
          ],
        },
      },
      {
        title: "Faculty",
        pathname: "/faculty",
        disabled: false,
      },
      {
        id: 5,
        title: "Alumni",
        pathname: "/Alumni",
        disabled: true,
      },
    ],
  },
  {
    id: 5,
    title: "IQAC",
    pathname: "/iqac",
    subnav_flag: true,
    subnav: [
      {
        title: "About IQAC",
        pathname: "/aboutIQAC",
        disabled: false,
      },
      {
        title: "Quality Policy",
        pathname: "/Qualitypolicy",
        disabled: false,
      },
      {
        title: "IQAC Objectives",
        pathname: "/IQACObjective",
        disabled: false,
      },
      {
        title: "Parameters of IQAC",
        pathname: "/IQACParameter",
        disabled: false,
      },
      {
        title: "Working Committee",
        pathname: "/workingcommitee",
        disabled: false,
      },
      {
        title: "Mandatory Document",
        pathname: "/mandatorydoc",
        disabled: false,
      },
    ],
  },
  {
    id: 6,
    title: "NIRF",
    pathname: "/nirf",
    disabled: false,
  },
  {
    id: 7,
    title: "NAAC",
    pathname: "/naac",
    disabled: false,
  },
  {
    id: 8,
    title: "Feedback",
    pathname: "/Feedback",
    disabled: false,
  },
  {
    id: 9,
    title: "Activities",
    pathname: "/activity",
    disabled: false,
  },
  {
    id: 10,
    title: "Contact",
    pathname: "/contactus",
    disabled: false,
  },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [activeSubNav, setActiveSubNav] = useState(null);
  const [isFixed, setIsFixed] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        height: "100%",
        width: "250px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Button sx={{ fontWeight: "bold", marginX:"0px",justifyContent:"start"
      }}>
      <CloseIcon sx={{
          transition: 'transform 0.3s ease',
        }}/>
      </Button>
      <Divider sx={{ bgcolor: "primary.contrastText" }} />

      {/* Navigation List */}
      <List sx={{ flexGrow: 1 }}>
        {navItems.map((item) => (
          <ListItem
            button
            key={item.id}
            onClick={() => handleNavigation(item)}
            sx={{
              "&:hover": {
                bgcolor: "primary.light",
                color: "primary.dark",
              },
            }}
          >
            <ListItemText
              primary={item.title}
              sx={{ textAlign: "left", paddingLeft: 2 }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const handleNavigation = (item, subItem = null) => {
    if (subItem) {
      const selected_data = { College: item, CourseOffered: subItem };
      navigate(subItem.pathname, { state: { College_details: selected_data } });
      setActiveSubNav(subItem.title === activeNavItem ? null : subItem.title);
    } else if (item.subnav_flag) {
      setActiveNavItem(item.id === activeNavItem ? null : item.id);
    } else {
      navigate(item.pathname);
    }
  };

  const isMenuItemActive = (item) => {
    if (item.subnav_flag) {
      return item.subnav.some((subItem) =>
        location.pathname.includes(subItem.pathname)
      );
    }
    return location.pathname.includes(item.pathname);
  };

  const renderSubMenu = (subnav, depth = 0) => (
    <Box
      sx={{
        position: "absolute",
        top: "100%",
        left: depth > 0 ? "100%" : 0,
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: 1,
        zIndex: 1000,
        padding: "8px 0",
        minWidth: "200px",
      }}
      onMouseLeave={() => setActiveSubNav(null)}
    >
      {subnav.map((subItem) => (
        <Box key={subItem.title} sx={{ position: "relative" }}>
          <MenuItem
            onClick={() => handleNavigation(null, subItem)}
            disabled={subItem.disabled === true}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              "&:hover": {
                backgroundColor: "#f2f2f2", // Hover effect
                cursor: "pointer",
                zoom: 1.05,
              },
            }}
          >
            {subItem.title}
            {subItem.subnav_flag && <ArrowForwardIosIcon fontSize="small" />}
          </MenuItem>
          {subItem.subnav &&
            activeSubNav === subItem.title &&
            rendernestedMenu(subItem.subnav, depth + 1)}
        </Box>
      ))}
    </Box>
  );

  const rendernestedMenu = (subnav, depth = 0) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "100%",
          left: depth > 0 ? "100%" : 0,
          backgroundColor: "white",
          boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
          borderRadius: 2,
          zIndex: 1000,
          padding: "12px 0",
          minWidth: "220px",
          width: "auto",
          maxHeight: "400px",
          overflowY: "auto",
        }}
        onMouseLeave={() => setActiveNavItem(null) && handleNavigation()}
      >
        {Object.keys(subnav).map((courseType) => (
          <Box key={courseType} sx={{ position: "relative" }}>
            <Typography
              variant="body2"
              sx={{
                paddingLeft: "16px",
                paddingBottom: "4px",
                fontWeight: "600",
                color: "Black",
                fontSize: "1rem",
              }}
            >
              {courseType}
            </Typography>
            <Divider sx={{ marginBottom: "8px", borderColor: "#e0e0e0" }} />{" "}
            {/* Lighter divider color */}
            <Box>
              {subnav[courseType].map((subItem) => (
                <MenuItem
                  key={subItem.pathname}
                  onClick={() => handleNavigation(null, subItem)}
                  disabled={subItem.disabled}
                  sx={{
                    display: "flex",
                    padding: "8px 16px",
                    fontSize: "small", // Increase padding for better spacing
                    "&:hover": {
                      backgroundColor: "#f2f2f2", // Hover effect
                      cursor: "pointer",
                      zoom: 1.1,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#f5f5f5", // Disabled state styling
                      color: "#b0b0b0", // Gray text for disabled items
                    },
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                  {subItem.title} {/* Course title */}
                </MenuItem>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderNavItems = () => {
    return navItems.map((item) => (
      <Box
        key={item.id}
        sx={{ position: "relative" }}
        onMouseEnter={() => item.subnav_flag && setActiveNavItem(item.id)}
        onMouseLeave={() => setActiveNavItem(null)}
      >
        <Button
          onClick={() => handleNavigation(item)}
          sx={{
            color: "white",
            textTransform: "none",
            padding: "10px",
            borderBottom: isMenuItemActive(item)
              ? "4px solid #F15215"
              : "4px solid transparent",
          }}
        >
          {item.title}
          {item.subnav_flag && <ArrowDropDownIcon sx={{ marginLeft: 1 }} />}
        </Button>
        {item.subnav_flag &&
          activeNavItem === item.id &&
          renderSubMenu(item.subnav)}
      </Box>
    ));
  };

  return (
    <section>
      <Box>
        <CssBaseline />
        <AppBar
          sx={{
            backgroundColor: "#F15215",
            position: "relative",
            maxHeight: "48px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
              marginLeft: "8rem",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={phone_black_icon} alt="Phone Icon" />
              <Typography
                variant="body2"
                sx={{ marginLeft: "8px", color: "white" }}
              >
                <a href="tel:040-27670675" style={{ color: "white" }}>
                  040 – 2767 06 75
                </a>{" "}
                /{" "}
                <a href="tel:+919100221282" style={{ color: "white" }}>
                  +91 9100221282
                </a>{" "}
                /{" "}
                <a href="tel:+918331041175" style={{ color: "white" }}>
                  +91 8331041175
                </a>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={Law_cat_code} alt="Law Cat Code" />
            </Box>
          </Box>
        </AppBar>
        <AppBar
          sx={{
            backgroundColor: "white",
            position: "relative",
            maxHeight: "100px",
            maxWidth: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: { xs: "16px", sm: "40px" },
            }}
          >
            <img
              src={FounderImage}
              alt="Founder"
              style={{
                width: "95px",
                position: "relative",
                height: "auto",
                bottom: "43px",
                marginBottom: { xs: "8px", sm: "0" },
              }}
            />

            {/* College Name */}
            <div
              style={{
                position: "relative",
                bottom: "21px",
                textAlign: "center",
              }}
            >
              <img
                src={logo_name1}
                alt="College Name"
                style={{
                  height: "6rem",
                  maxWidth: "100%",
                }}
              />
            </div>

            {/* College Certifications */}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-end" },
                alignItems: "center",
                marginTop: { xs: "10px", sm: "0px" },
                marginLeft: { xs: "0", sm: "auto" },
                marginBottom: { xs: "0", sm: "2rem" },
              }}
            >
              <img
                src={LawCollegeCertifications}
                alt="College Certifications"
                style={{
                  height: "5rem",
                  maxWidth: "100%",
                }}
              />
            </Box>
          </Box>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#494798",
            position: isFixed ? "fixed" : "relative",
            width: "100%",
            top: isFixed ? 0 : "unset",
            zIndex: 1100,
            transition: "top 0.3s ease",
          }}
        >
          <Box
            display={isMobile ? "block" : "flex"}
            style={{
              marginLeft: isMobile ? "1rem" : "2rem",
              transition: "margin 0.3s ease",
            }}
          >
            {isMobile ? (
              <>
                {" "}
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                  sx={{ display: { lg: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }} // Improves performance on mobile.
                >
                  {drawer}
                </Drawer>
              </>
            ) : (
              renderNavItems() // Render navigation items for larger screens
            )}
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default Header;
