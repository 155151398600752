import React from "react";
import "../../Styles/Course_Offering.css";
import { Grid, Typography } from "@mui/material";
import JSONDATA from "../../Assets/JSON/MV.json";
import { useNavigate } from "react-router-dom";
import Course_Offering_Header from "../../Assets/Images/Courses_Header.png";

const Course_Offering = () => {
  const navigate = useNavigate();

  const Navigation_Sub_route = (data, dep) => {
    const selected_data = { College: data, CourseOffered: dep };
    navigate(
      "/courseoffering/" +
        data.title.toLowerCase().replace(/ /g, "-") +
        "/" +
        dep.title.toLowerCase().replace(/ /g, "-"),
      { state: { College_details: selected_data } }
    );
  };
  return (
    <section className="container mt-5 Course_Header">
       <img src={Course_Offering_Header} alt="Course Offering" className="mt-4 center w-100"  />
      <Grid container sx={{justifyContent: "center"}}>
        {JSONDATA.sub_heading?.map((subItem, Index) => (
          <Grid item key={Index} className="my-4">
            <div className="d-flex px-5">
              <Typography variant="subtitle5" className="m-3 course_offering_heading p-2">
                {subItem.title}
              </Typography>
            </div>
            <div className="mt-2 px-5">
              <ul className="course_offering_list">
                {subItem.subdeparment?.map((subData, subDataIndex) => (
                  <li key={subDataIndex} className="p-2 my-2 mx-3">
                    <Typography
                      variant="body5"
                      onClick={() => {
                        Navigation_Sub_route(subItem, subData);
                      }}
                      sx={{cursor:"pointer"}}
                    >
                      {subData.title}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default Course_Offering;
