import React from "react";
import { Typography } from "@mui/material";

const MVComponent = ({ getdata }) => {
  const data = getdata;
  return (
    <section className="container mt-5">
      <div className="text-center py-5">
          <img
            src={
              data.image === ""
                ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                : data.image
            }
            height={"auto"}
            className="w-25"
            alt="MV-Data"
          />
          <Typography variant="h5" className="text-center mt-4">{data.tittle}</Typography>
        <Typography variant="body1" className="multine-ellipsis-4 body1_color  mt-3">{data.description}</Typography>
      </div>     
    </section>
  );
};

export default MVComponent;
