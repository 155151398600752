import React, { useState } from "react";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  CardActions,
} from "@mui/material";
import ExpandableText from "../../Pages/HS/ExpandableText";
import Black_Arrow from "../../Assets/Icons/Black_Arrow.svg";
import ImageCarosel from "../../Component/Carosel/Image_Carosel";

const CardEvent = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);

  const openDialog = (index) => {
    setSelectedEventIndex(index);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedEventIndex(null);
    setDialogOpen(false);
  };

  return (
    <section>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} style={{ alignContent: "center" }} onClick={() => openDialog(0)}>
          <ImageCarosel
            image_data={
              props.getdata[0]?.image?.length > 0
                ? props.getdata[0].image
                : [
                    "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg",
                  ]
            }
            onClick={() => openDialog(0)}
            alt={`card_image_0`}
          />
          <Typography variant="h6" className="mt-2">
            {props.getdata[0]?.title}
          </Typography>
          <ExpandableText
            text={props.getdata[0]?.description}
            initialChars={500}
            showFullText={false}
          />
          <div className="d-flex align-items-center justify-content-between mt-2">
            <Button size="small">{props.getdata[0]?.event_date}</Button>
            <img
              src={Black_Arrow}
              onClick={() => openDialog(0)}
              alt="BlackArrow"
            />
          </div>
        </Grid>

        {/* Smaller Cards */}
        <Grid item xs={12} md={4}>
          {props.getdata.slice(1).map((item, index) => (
            <Card key={index} className="mb-3" style={{ padding: "5px" }} onClick={() => openDialog(index + 1)}>
              <ImageCarosel
                image_data={
                  item.image?.length > 0
                    ? item.image
                    : [
                        "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg",
                      ]
                }
                onClick={() => openDialog(index + 1)}
                imageStyle={{
                  width: "100%",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <Typography variant="h6" noWrap style={{ marginTop: "8px" }}>
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {item.description}
              </Typography>
              <div className="d-flex align-items-center justify-content-between mt-2">
            <Button size="small">{item.event_date}</Button>
            <img
              src={Black_Arrow}
              onClick={() => openDialog(index + 1)}
              alt="BlackArrow"
            />
          </div>
            </Card>
          ))}
        </Grid>
      </Grid>

      {/* Dialog for Image Details */}
      {props.getdata.map((item, index) => (
        <Dialog
          key={index}
          open={dialogOpen && selectedEventIndex === index}
          onClose={closeDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{item.title}</DialogTitle>
          <DialogContent>
            <Typography>
              <ExpandableText
                text={item.description}
                initialChars={100}
                showFullText={false}
              />
            </Typography>
            <ImageCarosel
              image_data={
                item.image?.length > 0
                  ? item.image
                  : [
                      "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg",
                    ]
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      ))}
    </section>
  );
};

export default CardEvent;
