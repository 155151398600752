import React from "react";
import { Button } from "@mui/material";
import CardEvent from "../../Component/Cards/CardEvent";
import CardCareer from "../../Component/Cards/CardCareer";
import Services_Carosel from "../../Component/Carosel/Services_Carosel";
import { useLocation, useNavigate } from "react-router-dom";

const Events = (Props) => {
  const get_Data = Props.Event_DATA;
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const navigateToActivitiesPage = () => {
    const isNotHome = pathname === "/home";
    const path = isNotHome ? "/activity" : `${pathname}/subject-activities`;
    navigate(path);
  };

  return (
    <section className="container mt-5">
      <div className="d-flex justify-content-between">
        <h2>{Props.title}</h2>
        <Button
          sx={{ color: "rgb(223,82,38)" }}
          onClick={() => navigateToActivitiesPage(get_Data[0].department)}
        >
          See All
        </Button>
      </div>

      <CardEvent getdata={get_Data}></CardEvent>
      {/* <CardCareer getdata={PBData}></CardCareer> */}
      {/* <Services_Carosel/> */}
    </section>
  );
};

export default Events;
