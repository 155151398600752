import React, { useState } from "react";
import FacultyData from "../../Assets/JSON/MV.json";
import HS from "../../Pages/HS/HS";
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  CardActions,
} from "@mui/material";
import twitterIcon from "../../Assets/Icons/Social_icon_twitter.svg";
import linkedinIcon from "../../Assets/Icons/Social_icon_linkedin.svg";

const Faculty = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPB, setSelectedPB] = useState(null);

  const handleOpenDialog = (pb) => {
    setSelectedPB(pb);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <section className="container py-2">
      <Typography component="div">
        <HS HS_DATA={FacultyData.HOD_Message} />
      </Typography>
      <>
        {FacultyData.FacultyData &&
          FacultyData.FacultyData.Faculty.length > 0 && (
            <div className="container">
              <div className="my-4 text-center">
                <Typography variant="h5">{FacultyData?.FacultyData.title}</Typography>
              </div>
              <Grid container spacing={2}>
                {FacultyData?.FacultyData &&
                  FacultyData?.FacultyData.Faculty.map((items) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className="px-2 my-2"
                        onClick={() => handleOpenDialog(items)}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="200"
                            image={
                              items.image === ""
                                ? "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg"
                                : items.image
                            }
                            sx={{objectFit:"contain"}}
                            alt="No Image"
                            onError={(e) =>
                              (e.target.src =
                                "https://dbraeiassetmanagement.blob.core.windows.net/dbraimt/prod/home/Placeholders/Placeholder_Image.jpg")
                            }
                            className="card_image_A"
                          />
                          <CardContent className="m-0 py-2 px-0"  sx={{alignItems: "center",display: "flex",flexDirection: "column"}}>
                            <Typography
                              className="m-0"
                              gutterBottom
                              variant="h6"
                              component="div"
                            >
                              {items.Name}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              className="text-primary"
                            >
                              {items.designation}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className="multine-ellipsis-2"
                            >
                              {items.descerption}
                            </Typography>
                          </CardContent>
                          <CardActions  sx={{alignItems: "center",display: "flex",flexDirection: "column"}} className="d-flex align-items-center justify-content-start">
                            <img src={twitterIcon} alt="twitter Icon" className="me-3" />
                            {/* <img src={linkedinIcon} alt="LinkedIn Icon"/> */}
                          </CardActions>
                        </CardActionArea>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          )}
      </>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPB ? selectedPB.tittle : ""}</DialogTitle>
        <DialogContent>
          <CardMedia
            component="img"
            height="140"
            image={selectedPB ? selectedPB.image : ""}
            alt="PB Image"
            style={{ width: "100%", height: "340px", objectFit: "cover" }}
          />
          <Typography variant="h5" color="text.secondary">
            {selectedPB ? selectedPB.Name : ""}
          </Typography>
          <CardActions className="d-flex align-items-center justify-content-start">
            <img src={twitterIcon} alt="socialmedia Icon" className="me-3"/>
            {/* <img src={linkedinIcon} alt="socialmedia Icon" /> */}
          </CardActions>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Faculty;
